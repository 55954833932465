// SHOULD BE CLEAN ABOVE THIS LINE
import algoliasearch from "algoliasearch/lite";
import $ from 'jquery';

import './classes/ChangeLanguage';
let client;

const sectionsInDom = ['categories', 'products', 'articles', 'resources'];
const namesInDom = ['Categories', 'Products', 'Articles', 'Free Resources & Guides'];
const searchInAlgolia = (value) => [
  {
    indexName: indexName("categories_index"),
    query: value,
    params: {
      hitsPerPage: 3,
    },
  },
  {
    indexName: indexName("products_index"),
    query: value,
    params: {
      hitsPerPage: 3,
    },
  },
  {
    indexName: indexName("posts_index"),
    query: value,
    params: {
      hitsPerPage: 3,
    },
  },
  {
    indexName: indexName("resources_index"),
    query: value,
    params: {
      hitsPerPage: 3,
    },
  },
  {
    indexName: indexName("buyers_guides_index"),
    query: value,
    params: {
      hitsPerPage: 3,
    },
  },
]

window.addEventListener("load", (event) => {
  client = algoliasearch(window.ALGOLIA_APP_ID, window.ALGOLIA_KEY_ID);
  // key pressed in the search input
  $('#searcher_home_htr').keyup(
    function (e){
    let value = e.target.value;

    if (value.length < 3) {
      sectionsInDom.forEach((section) => {
        $(`#${section}`).empty();
      });
      return false;
    } else {

      client.search(searchInAlgolia(value), (err, { results } = {}) => {
        if (err) throw err;
        let result = { ...handleResponse(results) };
        setResultIntoDom(result, value, sectionsInDom, namesInDom, 'searcher_home_htr');
      });
    }
  });

  $('#searcher_two_home_hero_htr').on('click', () => {
    let search = $('#searcher_htr');
    $(search).css({'visibility': 'hidden', 'opacity': '0', 'margin-right': '0px'});
    $(search).fadeOut(100);
  });

  $('#searcher_two_home_hero_htr').on('blur', () => {
    let search = $('#drowdown_searcher_two_hero_htr');
    $(search).css({'visibility': 'hidden'});
    $(search).fadeOut(100);
  });

  // Dispara keyup cuando el input recibe focus
  $('#searcher_two_home_hero_htr').on('focus', () => {
    const keyupEvent = new KeyboardEvent('keyup', {
      key: '', // Puedes personalizar la tecla simulada si quieres
      bubbles: true,
      cancelable: true
    });
    $('#searcher_two_home_hero_htr').get(0).dispatchEvent(keyupEvent);
  });
});

/*
// NEW COMPONENTS
*/

// ----------------------------------------------
//  START - SEARCH ICON - SECTION
// ----------------------------------------------
// click on searh icon

function indexName (key) {
  return window.ENVIRONMENT + '_' + key;
}

function handleResponse (results) {
  return results.reduce(
    (accumulator, item) => {

      if (item.index === indexName("categories_index"))
        accumulator.categories = item;
      if (item.index === indexName("products_index"))
        accumulator.products = item;
      if (item.index === indexName("posts_index"))
        accumulator.articles = item;
      if (
        [
          indexName("resources_index"),
          indexName("buyers_guides_index"),
        ].includes(item.index)
      ) {
        if (!Object.keys(accumulator.resources).length) {
          accumulator.resources = item;
        } else {
          accumulator.resources.nbHits += item.nbHits;
          accumulator.resources.hits = [
            ...accumulator.resources.hits,
            ...item.hits,
          ];
          accumulator.resources.hits = accumulator.resources.hits.slice(
            0,
            3
          );
        }
      }

      return accumulator;
    },
    { categories: {}, articles: {}, resources: {}, products: {} }
  );
}

function setResultIntoDom(result, query, sectionsInDom, namesInDom, idSearch) {
  sectionsInDom.forEach((section, index) => {
    if (result[section]) {
      const html = generateSectionHtml(result[section], query, namesInDom[index], section);
      $(`#${idSearch}${section}`).empty().append(html);
    }
  });
}

function generateSectionHtml(sectionResult, query, sectionName, section) {
  let html = `<div class="search-item">
    <div class="search-item__header">
      ${sectionName}
      <span>(${sectionResult.nbHits} results)</span>
      <a href="/s/${query}/${section}">View more</a>
    </div>
    <ul class="search-item__body">`;

  sectionResult.hits.forEach(hit => {
    html += generateHitHtml(hit, section);
  });

  html += '</ul></div>';
  return html;
}

function generateHitHtml(hit, section) {
  if (section === 'products') {
    return generateProductHitHtml(hit);
  } else {
    return `<li>
      <a href="${hit.url}">
        <span>${hit.title}</span>
      </a>
    </li>`;
  }
}

function generateProductHitHtml(hit) {
  let html = `<li class="search-item__body-product">
    <a href="${hit.url}">
    <img src="${hit.logo ? hit.logo : '/images/company-default-logo.png'}"></img>
    <div>
      <span class="search-product__title">${hit.title}</span>
      <span class="search-product__category">${hit.category.title}</span>
    </div>
    <input type="hidden" value="${hit.rating}"></input>`;

  if (hit.rating) {
    html += generateRatingHtml(hit.rating);
  }

  html += '</a></li>';
  return html;
}

function generateRatingHtml(rating) {
  const fullRating = Number(rating);
  let html = '<ul class="rating">';
  for (let i = 1; i <= 5; i++) {
    html += '<li>';
    if (fullRating >= i) {
      html += '<img src="/images/star-full-blue.svg" width="18"></img>';
    } else if (i - fullRating <= 0.5) {
      html += '<img src="/images/star-half-blue.svg" width="18"></img>';
    } else {
      html += '<img src="/images/star-empty-blue.svg"></img>';
    }
    html += '</li>';
  }
  html += '</ul>';
  return html;
}

// key pressed in the search input
$('#searcher_two_home_hero_htr').keyup(function (e){
  let value = e.target.value;

  if (value.length < 3) {
    sectionsInDom.forEach((section) => {
      $(`#${section}`).empty();
    });
    return false;
  } else {
    client.multipleQueries(searchInAlgolia(value)).then(function ({results}) {
      let result = { ...handleResponse(results) };
      let search = $('#drowdown_searcher_two_hero_htr')
      $(search).css({ 'visibility': 'visible' })
      $(search).fadeIn(100)
      let fullScren = $('#divFullScreenSearcherTwo_htr')
      $(fullScren).css({ 'display': 'block' })
      setResultIntoDom(result, value, sectionsInDom, namesInDom, 'searcher_two_home_hero_htr')
    }).catch(function (err) {
      console.log(err)
      if (err) throw err
    })
  }
});

$('#submitSubscribe_htr').on('click', (event) => {
  event.preventDefault();
  let value = $("#suscribeEmail_htr") ? $("#suscribeEmail_htr")[0].value : null;
  const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  let isEmail = re.test(String(value).toLowerCase());
  if (!isEmail) {
    $('#alertError_htr').show().delay(4000).hide(0);
    return false
  }
  $.post('/blog-subscriptions', { email: value })
    .done(function () {
      $('#alertSuccess_htr_alternative').show().delay(4000).hide(0);
    })
    .fail(function (response) {
      let errorMessage = response.responseJSON?.message ?? 'An unknown error occurred';
      $('#alertError_htr_alternative').text(errorMessage).show().delay(4000).hide(0);
    })
});

$('.icon-magnifier').on('click', (event) => {
  //callSearchEngine();
  return true;
});

// press enter on input field
$(document).on("keypress", "#home-what-can-we-help-you-find", function(e){
  if(e.which == 13){
    callSearchEngine();
  }
});

// function to redirect to searh engine
function callSearchEngine () {
  let pageURL = $(location).attr('protocol') + '//' + $(location).attr('host') + '/s?query=';
  let searchValue = $('#home-what-can-we-help-you-find').val();
  let win = window.open(pageURL + searchValue, '_blank');
  if (win) {
    // Browser has allowed it to be opened
    win.focus();
  } else {
    // Browser has blocked it
    alert('Please allow popups for this website');
  }
  return true;
}
// ----------------------------------------------
//  END - SEARCH ICON - SECTION
// ----------------------------------------------
export { handleResponse, callSearchEngine, searchInAlgolia, indexName, setResultIntoDom };
